<template>
  <div v-if="successShow">
    <div class="info">
      <img :src="require('@/assets/img/message-agree.png')" class="success-img" />
      <div class="success-text" >登记成功</div>
    </div>
    <div class="times">
      <div>登记时间：<span>{{signTime}}</span></div>
    </div>
  </div>
</template>

<script>
import {formatterDateTimeWithOutSec} from "@/utils/utils"
export default {
  data() {
    return {
      successShow: false,
      activityId: '',
      activityName: '',
      applyTime: '',
      signTime: '',
    //   isOld: sessionStorage.getItem('isOld')
    }
  },
  created() {
    // this.activityId = this.$route.query.id || ''
    // this.activityName = this.$route.query.title || ''
    // this.applyTime = this.$route.query.applyTime || ''
    // this.signIn()
    this.init()
  },
  methods: {
    init() {
        this.signTime = formatterDateTimeWithOutSec(new Date())
        this.successShow = true
    }
    // signIn() {
    //   this.$toast.loading({ duration: 0, message: '加载中...', forbidClick: true, });
    //   this.$httpSync({
    //     url: this.$httpSync.adornUrl(`/wxapp/activity/sign/sign`),
    //     method: 'post',
    //     params: this.$httpSync.adornParams({
    //       activityId: this.activityId,
    //       userId: sessionStorage.getItem('orgUserId'),
    //       community: sessionStorage.getItem('orgId')
    //     })
    //   }).then(({data}) => {
    //     this.$toast.clear()
    //     if (data && data.code === 0) {
    //       this.signTime = formatterDateTimeWithOutSec(new Date())
    //       this.successShow = true
    //     } else {
    //       this.$toast.fail(data.msg)
    //     }
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
.info {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  .title {
    font-size: 30px;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    color: #333333;
    margin-bottom: 42px;
  }
  .success-img {
    width: 300px;
    height: 300px;
  }
  .success-text {
    font-size: 36px;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    color: #4581F8;
  }
}
.times {
  width: 100%;
//   height: 160px;
  background: #FFFFFF;
  font-size: 30px;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
  color: #999999;
  padding: 30px 0;
  text-align: center;
  margin-top: 24px;
  span {
    color: #333333;
  }
}
</style>